import { Dispatch } from 'redux';
import { CURRENCY_SYMBOLS } from '../constants/currency';

export const CURRENCY_SET = 'currency/SET';

const initialState: Currency = {
    code: '',
    loaded: false,
    symbol: ''
} as unknown as Currency;

type CurrencyAction = {
    type: string;
    currency: Currency;
};

export default (state = initialState, action: CurrencyAction) => {
    switch (action.type) {
        case CURRENCY_SET:
            return {
                ...state,
                ...action.currency
            };
        default:
            return state;
    }
};

export const setCurrency = (currency: Pick<Currency, 'code'>) => (dispatch: Dispatch) => {
    if (currency.code) {
        window.storage.setItem('currencyCode', currency.code);

        return dispatch({
            currency: {
                code: currency.code,
                loaded: true,
                symbol: CURRENCY_SYMBOLS[currency.code] ? CURRENCY_SYMBOLS[currency.code] : ''
            },
            type: CURRENCY_SET
        });
    }
};
