const buildFoldersTree = (folders: Folder[]) => {
    const map = {};
    const tree: Folder[] = [];

    // Create a map of folders using their IDs as keys
    folders.forEach((folder) => {
        map[folder.id] = { ...folder, children: [] };
    });

    // Build the tree structure
    folders.forEach((folder) => {
        if (folder.parent_id) {
            // If the folder has a parent, add it as a child of the parent folder
            map[folder.parent_id].children.push(map[folder.id]);
        } else {
            // If the category has no parent, it is a root category
            tree.push(map[folder.id]);
        }
    });

    return tree;
};

export default buildFoldersTree;
