const sha256 = {
    hash: async (message: string) => {
        // encode the message as a Uint8Array
        const msgUint8 = new TextEncoder().encode(message);

        // hash the message using SHA-256
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);

        // convert the ArrayBuffer to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        return hashArray.map((byte) => `00${byte.toString(16)}`.slice(-2)).join('');
    }
};

export default sha256;
