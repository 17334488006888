import 'core-js/stable/object';
import 'core-js/stable/map';
import 'core-js/stable/set';
import 'core-js/stable/string';
import 'core-js/stable/promise';
import 'core-js/stable/array';
import 'core-js/stable/symbol';
import 'core-js/stable/number';
import 'core-js/stable/weak-map';
import 'core-js/stable/url-search-params';
import './utils/remove-polyfill';
// eslint-disable-next-line import/imports-first
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line
import React from 'react';
// eslint-disable-next-line
import Entry from './entry';

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);

root.render(<Entry />);

// ReactDOM.render(<Entry />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
