const materialContainsDownloadableFiles = (item: Material) => {
    const { materialFiles = [] } = item;

    return (
        materialContainsTeachingFiles(item) || materialFiles?.some((file) => ['sources', 'cover'].includes(file.type))
    );
};

export const materialContainsTeachingFiles = (item: Material) => {
    const { materialFiles = [] } = item;

    return materialFiles?.some((file) => ['material_file'].includes(file.type));
};

export default materialContainsDownloadableFiles;
