import session from './session';

let _user = { profileDetails: { invoiceDetails: {} } } as User;

export const setUserData = (user: User): void => {
    _user = user;
};

export const getUserData = (): User => _user;

export const storedUserData = () => ({
    isAuthor: String(Number(JSON.parse(window.storage.getItem('isSeller') || 0))),
    
    loginState: Boolean(session.isLoggedIn()),
    // id: window.storage.getItem('userId'),
signUpDate: window.storage.getItem('userSD'),
    
    signUpSource: window.storage.getItem('creationSource'),
    // "searchType": storage.getItem('searchType'),
subjects: ''
});
