import isPrerender from './is-prerender';
import setCookieForSubdomain from './set-cookie-for-subdomain';

const lastVisitedWorld = {
    get() {
        if (isPrerender()) {
            return null;
        }

        return localStorage.getItem('lastVisitedWorld');
    },
    set(world: string) {
        if (window.location.pathname.indexOf(`/${world}`) === 0) {
            localStorage.setItem('lastVisitedWorld', world);
            setCookieForSubdomain('lastVisitedWorld', world);
        }
    }
};

export default lastVisitedWorld;
