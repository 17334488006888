export type Hostnames = {
    id: number;
    name: string;
    world: string;
    materialIds?: string[];
};

const hostnames: Hostnames[] = [
    {
        id: 42,
        name: 'kolupaev.com',
        world: 'de'
    },
    {
        id: 116964,
        name: 'froileinkunterbunt.de',
        world: 'de'
    },
    {
        id: 115618,
        name: 'unterrichtmitspass.com',
        world: 'de'
    },
    {
        id: 72730,
        name: 'vonbecky.de',
        world: 'de'
    },
    {
        id: 77784,
        name: 'allerleifuerdieschule.de',
        world: 'de'
    },
    {
        id: 91978,
        name: 'kreativeschatzkiste.de',
        world: 'de'
    },
    {
        id: 30770,
        name: 'grundschulgedoens.de',
        world: 'de'
    },
    {
        id: 201306,
        name: 'fraufausw.de',
        world: 'de'
    },
    {
        id: 77433,
        name: 'msdoubtfire.de',
        world: 'de'
    },
    {
        id: 67952,
        name: 'giraffesloveschool.de',
        world: 'de'
    },
    {
        id: 172,
        name: 'doodleteacher.net',
        world: 'de'
    },
    {
        id: 172,
        name: 'doodleteacher.de',
        world: 'de'
    },
    {
        id: 69475,
        name: 'schulallerlei.de',
        world: 'de'
    },
    {
        id: 1676,
        name: 'thegrowingfirsties.de',
        world: 'de'
    },
    {
        id: 10046,
        name: 'betterteachingresources.de',
        world: 'de'
    },
    {
        id: 137782,
        name: 'frauleinlehrerin.de',
        world: 'de'
    },
    {
        id: 155096,
        name: 'grundschul-liebe.com',
        world: 'de'
    },
    {
        id: 113690,
        name: 'oddbirdandcompany.de',
        world: 'de'
    },
    {
        id: 298999,
        name: 'laminierparty.de',
        world: 'de'
    },
    {
        id: 127651,
        name: 'nekonekogrundschule.com',
        world: 'de'
    },
    {
        id: 126893,
        name: 'kurznachacht.de',
        world: 'de'
    },
    {
        id: 247389,
        name: 'ninanalehrerinmitherz.com',
        world: 'de'
    },
    {
        id: 1351256,
        name: 'psicomporienta.es',
        world: 'es'
    },
    {
        id: 1706197,
        name: 'tsilla.fr',
        world: 'fr'
    },
    {
        id: 1901159,
        name: 'jouer-pour-apprendre.fr',
        world: 'fr'
    },
    {
        id: 1351391,
        name: 'maestrossyapilar.es',
        world: 'es'
    },
    {
        id: 1927010,
        name: 'ubuntuintheclass.es',
        world: 'es'
    },
    {
        id: 1429956,
        name: 'educarpensandoconelcorazon.es',
        world: 'es'
    },
    {
        id: 1355449,
        name: 'entretizasyceras.es',
        world: 'es'
    },
    {
        id: 1351385,
        name: 'mentesaplicadas.es',
        world: 'es'
    }
    //
    // {
    //     name: 'lehrermarktplatz.ua',
    //     world: 'de',
    //     id: 42
    // }
];

export default hostnames;
