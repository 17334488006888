import { materialContainsTeachingFiles } from '../material-contains-downloadable-files';

const isInteractiveStandaloneMaterial = (item: Material) => {
    if (typeof item?.isStandaloneInteractive !== 'undefined') {
        return item.isStandaloneInteractive;
    }

    return Boolean(item.interactiveId && !materialContainsTeachingFiles(item));
};

export default isInteractiveStandaloneMaterial;
