const getTopLevelDomain = () => {
    const fullDomain = window.location.hostname;
    const parts = fullDomain.split('.');
    const topLevelDomain = parts.slice(-2).join('.');
    let separator = '.';

    if (topLevelDomain === 'localhost') {
        separator = '';
    }

    return `${separator}${topLevelDomain}`;
};

const setCookieForSubdomain = (name: string, value: string) => {
    const domain = `domain=${getTopLevelDomain()}`;

    document.cookie = `${name}=${value};${domain};path=/`;
};

export default setCookieForSubdomain;
