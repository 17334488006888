import { AVAILABLE_WORLDS } from '../languages/constants';

const getWorldFromUrl = () => {
    const worlds = Object.values(AVAILABLE_WORLDS);

    for (let i = 0; i < worlds.length; i++) {
        if (window.location.pathname.indexOf(`/${worlds[i]}`) === 0) {
            return worlds[i];
        }
    }
};

export default getWorldFromUrl;
