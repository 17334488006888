import { BaseService } from '@usercentrics/cmp-browser-sdk';
import usercentrics from '../services/usercentrics';

export const COOKIE_CONSENT_SET = 'cookie-consent/SET';

const initialState: CookieConsentState = {
    services: []
};

export type CookieConsentState = {
    services: BaseService[];
};

export type CookieConsentAction = {
    payload: CookieConsentState;
    type: string;
};

export default (state = initialState, action: CookieConsentAction) => {
    switch (action.type) {
        case COOKIE_CONSENT_SET:
            return {
                ...action.payload
            };
        default:
            return state;
    }
};

export const initCookiesConsent = (): ThunkAction<void, RootState, unknown, CookieConsentAction> => (dispatch) => {
    const services = usercentrics.getServicesData();

    dispatch({
        payload: {
            services
        },
        type: COOKIE_CONSENT_SET
    });

    window.cookiesServicesData = services;
};
