const detectBrowser = (): string | null => {
    const agent = navigator.userAgent;
    let browser = null;

    if (/Opera\/|OPR\//.test(agent)) {
        browser = 'Opera';
    } else if (/Edg\//.test(agent)) {
        browser = 'Edge';
    } else if (/Chrome\//.test(agent)) {
        browser = 'Chrome';
    } else if (/Safari\//.test(agent)) {
        browser = 'Safari';
    } else if (/Firefox\//.test(agent)) {
        browser = 'Firefox';
    } else if (/MSIE\//.test(agent)) {
        browser = 'Internet Explorer';
    }

    return browser;
};

export default detectBrowser;
