import { Dispatch } from 'redux';
import API_URLS from '../constants/api-urls';
import axios from '../utils/axios';

export const FILTERS_GET = 'filters/GET';

const initialState: Filters = {
    class_grade: [],
    learning_years: [],
    material_type: [],
    subject: []
};

export default (state = initialState, action: { type: string; filters: Filters }) => {
    switch (action.type) {
        case FILTERS_GET:
            return {
                ...state,
                ...action.filters
            };
        default:
            return state;
    }
};

export const getFilters = () => (dispatch: Dispatch) => {
    axios.get(API_URLS.FILTERS).then((res) => {
        dispatch({
            filters: res.data.items,
            type: FILTERS_GET
        });
    });
};
