import { Dispatch } from 'redux';
import API_URLS from '../constants/api-urls';
import axios from '../utils/axios';
import formatPrice from '../utils/format-price';
import isAuthorDomain from '../utils/is-author-domain';

export const CART_GET = 'cart/GET';

const initialState: Cart = {
    cartItems: []
} as unknown as Cart;

export const cartContainsFixedPriceOnly = (cart: Cart) => {
    let fixedPriceOnly = true;

    cart.cartItems.forEach((item) => {
        if (!item.material.hasFixedPrice) {
            fixedPriceOnly = false;
        }
    });

    return fixedPriceOnly;
};

type CartAction = {
    type: string;
    cart: Partial<Cart>;
};

export default (state = initialState, action: CartAction) => {
    switch (action.type) {
        case CART_GET:
            return {
                ...state,
                ...action.cart
            };
        default:
            return state;
    }
};

export const getCart = () => (dispatch: Dispatch) => {
    axios.get(`${API_URLS.CART}view`, { withCredentials: true }).then(
        (res) => {
            const { cart } = res.data.items as { cart: Cart };

            cart.loaded = true;
            cart.originalPrice = formatPrice(cart.originalPrice);
            cart.finalPrice = formatPrice(cart.finalPrice);
            cart.totalVoucherDiscount = cart.totalVoucherDiscount ? formatPrice(cart.totalVoucherDiscount) : 0;
            cart.cartItems = cart.cartItems.map((item) => {
                const formattedItem = item;

                formattedItem.finalPrice = formatPrice(item.finalPrice);

                return formattedItem;
            });
            dispatch({
                cart,
                type: CART_GET
            });
        },
        () => {}
    );
};

export const reset = (new_cart: Cart) => (dispatch: Dispatch) => {
    const cart: Partial<Cart> = {};

    cart.itemsInBundles = new_cart.itemsInBundles;
    cart.loaded = true;
    cart.voucher = new_cart.voucher;
    cart.usedCredits = new_cart.usedCredits;
    cart.originalPrice = parseFloat(new_cart.originalPrice).toFixed(2);
    cart.finalPrice = parseFloat(new_cart.finalPrice).toFixed(2);
    cart.cartItems = new_cart.cartItems;
    cart.totalVoucherDiscount = new_cart.totalVoucherDiscount ? formatPrice(new_cart.totalVoucherDiscount) : 0;
    cart.voucherError = new_cart.voucherError;
    cart.cartItems.forEach((item) => {
        item.finalPrice = formatPrice(item.finalPrice);
    });

    if (isAuthorDomain()) {
        window.storage.setItem('cart_token', new_cart.token);
    }

    dispatch({
        cart,
        type: CART_GET
    });
};
