import { ComponentType, ComponentProps } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FALLBACK_WORLD } from './constants';
import getWorldFromUrl from '../utils/get-world-from-url';
import isAuthorDomain, { AuthorDomain } from '../utils/is-author-domain';
import lastVisitedWorld from '../utils/last-visited-world';

const detectWorld = (): string => {
    const ad: AuthorDomain = isAuthorDomain();

    // @ts-ignore
    return ad?.world || getWorldFromUrl() || lastVisitedWorld.get() || FALLBACK_WORLD;
};

const namespaceExists = (namespace: string) => {
    if (i18n.reportNamespaces) {
        // @ts-ignore
        const { usedNamespaces } = i18n.reportNamespaces;

        return usedNamespaces[namespace];
    }
};

export const world: string = detectWorld();

// eslint-disable-next-line func-names
const translation = (ns: string | string[]) => {
    const namespaces = Array.isArray(ns) ? ns : [ns];

    namespaces.forEach((namespace) => {
        if (namespace && !namespaceExists(namespace)) {
            import(`../languages/${world}/${namespace}.json`).then(({ default: data }) => {
                if (i18n.addResourceBundle) {
                    i18n.addResourceBundle(world, namespace, data);
                }
            });
        }
    });

    return <T extends ComponentType<ComponentProps<T>>>(WrappedComponent: T) =>
        withTranslation(namespaces)(WrappedComponent);
};

export default translation;
