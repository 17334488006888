const schoolAccount = {
    getData: (user: User) => {
        return user?.profileDetails?.details?.schoolAccount || {} as SchoolAccount;
    },
    hasSchool: (user: User) => {
        return (
            user?.profileDetails?.details?.schoolAccount?.school_id
        );
    },
    isAdmin: (user: User) => {
        return user?.profileDetails?.details?.schoolAccount?.role === 'master';
    },
    isVerifiedUser: (user: User) => {
        return user?.profileDetails?.details?.schoolAccount?.is_verified;
    }
};

export default schoolAccount;
