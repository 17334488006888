import axios from './axios';
import { world } from '../languages';
import errorTracker from './error-tracker';
import { requestInterceptor, responseInterceptor } from './interceptor';

window.pendingRequests = {};

const initInterceptors = () => {
    const pendingRequestsTimeout = {};

    // @ts-ignore
    axios.interceptors.request.use((config) => {
        // @ts-ignore
        let url = config.baseURL + config.url;
        const worldMixin = { world };

        if (config?.params?.world) {
            worldMixin.world = config?.params?.world;
        }

        if (!url.includes('pearup')) {
            config.params = config.params ? { ...config.params, ...worldMixin } : { ...worldMixin };
        }

        // @ts-ignore
        if (config.url.indexOf('http') >= 0) {
            // @ts-ignore
            url = config.url;
        }

        window.pendingRequests[url] = 'pending';

        pendingRequestsTimeout[url] = setTimeout(() => {
            errorTracker.captureMessage(`${url} took more than 3s`);
        }, 3000);

        return requestInterceptor(config);
    });

    axios.interceptors.response.use(
        (response) => {
            const { config = {} } = response;
            // @ts-ignore
            const { url, baseURL } = config;

            let requestUrl = baseURL + url;

            // @ts-ignore
            if (url?.indexOf('http') >= 0) {
                // @ts-ignore
                requestUrl = url;
            }
            // @ts-ignore
            delete window.pendingRequests[requestUrl];
            // @ts-ignore
            clearTimeout(pendingRequestsTimeout[requestUrl]);

            return response;
        },
        (err = {}) => {
            const { config = {} } = err;
            const { url, baseURL } = config;

            let requestUrl = baseURL + url;

            // @ts-ignore
            if (url?.indexOf('http') >= 0) {
                // @ts-ignore
                requestUrl = url;
            }

            delete window.pendingRequests[requestUrl];

            return responseInterceptor(err);
        }
    );

    axios.interceptors.response.use((response) => response.data);
};

export default initInterceptors;
