import { combineReducers } from 'redux';

import cart from './cart';
import filters from './filters';
import user from './user';
import favorite from './favorite';
import currency from './currency';
import geotargeting from './geotargeting';
import websocket from './websocket';
import cookieConsent from './cookie-consent';
import folders from './folders';
import searchResults from './search-results';

const main = combineReducers({
    cart,
    cookieConsent,
    currency,
    favorite,
    filters,
    folders,
    geotargeting,
    searchResults,
    user,
    websocket
});

export default main;
