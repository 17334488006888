import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import createRootReducer from './root-reducer';

// create history
// export const history = createHistory();
// define on your own as per requirment
const store = createStore(createRootReducer(), composeWithDevTools(applyMiddleware(thunk)));

// @ts-ignore
store.asyncReducers = {};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
