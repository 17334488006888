import md5 from './md5';
import session from './session';
import sha256 from './sha256';

const MD5_EMAIL_KEY = 'emailHash';
const SHA256_EMAIL_KEY = 'emailSHA256Hash';

const emailHash = {
    getStoredMD5: (): string => {
        return window.storage.getItem(MD5_EMAIL_KEY);
    },
    storeMD5: (email = ''): void => {
        if (session.impersonated() || !email) {
            return;
        }

        window.storage.setItem(MD5_EMAIL_KEY, md5(email));
    },
    storeSHA256: (email = ''): void => {
        try {
            if (session.impersonated() || !email) {
                return;
            }

            sha256.hash(email).then((hash) => {
                window.storage.setItem(SHA256_EMAIL_KEY, hash);
            });
        } catch (e) {}
    },
    getStoredSHA256: (): string => {
        return window.storage.getItem(SHA256_EMAIL_KEY);
    }
};

export default emailHash;
