import WebsocketClient from '../utils/websocket-client';
import session from '../utils/session';

export const WEBSOCKET_UPDATE = 'websocket/UPDATE';

type WebsocketState = {
    connected: boolean,
    emit: (arg: EmitMessage) => void,
    lastMessage: IWebsocketMessage | null
}

const initialState: WebsocketState = {
    connected: false,
    emit: () => {},
    lastMessage: null
};

type IWebsocketAction = {
    payload: Partial<WebsocketState>;
    type: string;
};

export default (state = initialState, action: IWebsocketAction) => {
    switch (action.type) {
        case WEBSOCKET_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const eventQueue: any[] = [];
let interval: NodeJS.Timer;

export const initWebsocket = (): ThunkAction<void, RootState, unknown, IWebsocketAction> => (dispatch) => {
    if (!session.isLoggedIn()) {
        return;
    }

    const onMessage = (data: IWebsocketMessage) => {
        if (data.type === 'heartbeat') {
            return;
        }

        if (data.type === 'handshake' && data.session_id) {
            dispatch({
                payload: { connected: true, emit },
                type: WEBSOCKET_UPDATE
            });
        } else {
            if (data.id) {
                emit({
                    // @ts-ignore
                    id: data.id,
                    type: 'ack'
                });
            }

            eventQueue.push(data);

            if (interval) {
                return;
            }

            interval = setInterval(() => {
                if (eventQueue.length) {
                    dispatch({
                        payload: {
                            emit,
                            lastMessage: eventQueue[0]
                        },
                        type: WEBSOCKET_UPDATE
                    });

                    eventQueue.shift();
                } else {
                    clearInterval(interval);
                    // @ts-ignore
                    interval = null;
                }
            }, 100);
        }
    };

    const socket = new WebsocketClient(onMessage);

    const emit = (data: EmitMessage) => {
        socket.instance.send(JSON.stringify(data));
    };
};
