const isNewMaterial = (material: Material): boolean => {
    if (!material.createdAt) {
        return false;
    }

    const creationTimestamp = new Date(material.createdAt).getTime(),
        now = new Date().getTime(),
        materialsAgeInMiliseconds = now - creationTimestamp,
        milisecondsPerDay = 1000 * 60 * 60 * 24,
        milisecondsPerTwoWeeks = 14 * milisecondsPerDay;

    return materialsAgeInMiliseconds < milisecondsPerTwoWeeks;
};

export default isNewMaterial;
