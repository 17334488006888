import axios from 'axios';
// test
// const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// instance.defaults.headers.common.Authorization = `Bearer ${session.getAccessToken()}`;

export default axios;
