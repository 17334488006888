import { world } from '../languages';

export const AGB_EXTERNAL_LINKS = {
    de: {
        orderProcessingAgreement:
            'https://drive.google.com/file/d/1eKDEv7wx8NSISqP0JQEYQfLlk8Elgwm7/view?usp=drive_link',
        withdrawalAgreement: 'https://drive.google.com/file/d/1fAe8tLcMcli0g6T3vqicC3PM2MTREp27/view?usp=drive_link'
    },
    es: {
        orderProcessingAgreement:
            'https://drive.google.com/file/d/10ckvHNVHCMjyDc7a1AWHD0WFmqhi7BpT/view?usp=drive_link',
        withdrawalAgreement: 'https://drive.google.com/file/d/1nK-AaK1ziNONdHHNn5fwHQJpWbQYKe29/view?usp=drive_link'
    },
    fr: {
        orderProcessingAgreement:
            'https://drive.google.com/file/d/1fFuc3aH4kSH3vfhjwdlbASVaNNUfK9jJ/view?usp=drive_link',
        withdrawalAgreement: 'https://drive.google.com/file/d/1CLyt8jpZfE9g5MA9qpl5BbuHxryKsK5_/view?usp=drive_link'
    },
    gr: {
        orderProcessingAgreement: 'https://drive.google.com/file/d/1MFhEIjsCMqBB2B_5wRMzjxDhBLKCs4_R/view?usp=sharing',
        withdrawalAgreement: 'https://drive.google.com/file/d/1dJSJkW08f-5nZFoF5dAVD72kscqwa6U6/view?usp=drive_link'
    },
    it: {
        orderProcessingAgreement:
            'https://drive.google.com/file/d/1Fjuuhy9eZeU-lh2TvxGI0J9mWvNqIx6a/view?usp=drive_link',
        withdrawalAgreement: 'https://drive.google.com/file/d/1o_NKWqZDQHcRyZ2Fyp1rXZ2J6ekSuQiO/view?usp=drive_link'
    }
};

export enum AGB_ANCHOR_NAMES {
    dataProtection = 'dataProtection',
    termsAndConditions = 'termsAndConditions',
    termsOfTask = 'termsOfTask',
    withdrawalProvisions = 'withdrawalProvisions'
}

export const AGB_ANCHORS = {
    default: {
        [AGB_ANCHOR_NAMES.dataProtection]: '#data_protection',
        [AGB_ANCHOR_NAMES.termsAndConditions]: '#terms-of-use',
        [AGB_ANCHOR_NAMES.termsOfTask]: '#terms-of-task-completing',
        [AGB_ANCHOR_NAMES.withdrawalProvisions]: '#withdrawal-provisions'
    }
};
export const getAGBAnchor = (anchorName: AGB_ANCHOR_NAMES): string => {
    const anchor = AGB_ANCHORS.default[anchorName];

    return anchor ?? '';
};
