const globalDebug = (function () {
    const savedConsole = console;

    return function(isDebugOn: boolean, allowedList: ('log' | 'info' | 'warn' | 'error')[] = []){
        if (isDebugOn === false) {
            // @ts-ignore
            // eslint-disable-next-line
            console = {};
            console.log = function () { };
            console.info = function () { };
            console.warn = function () { };
            console.error = function () { };
            if(allowedList?.length) {
                allowedList.forEach(allowedMethod => {
                    console[allowedMethod] = savedConsole[allowedMethod];
                });
            }
        } else {
            // eslint-disable-next-line
            console = savedConsole;
        }
    };
})();

export default globalDebug;
