import posthog, { Properties } from 'posthog-js';
import POSTHOG from '../constants/posthog';
import { isInteractiveMaterial } from './interactive/is-interactive-material';
import isInteractiveStandaloneMaterial from './interactive/is-interactive-standalone-material';
import isPrerender from './is-prerender';
import isProduction from './is-production';
import isUITest from './is-ui-test';
import serializeQueryParams from './serialize-query-params';

const isPosthogForbidden = () => isUITest() || isPrerender();

export const initPostHog = () => {
    const apiKey = isProduction() ? POSTHOG.API_KEYS.PRODUCTION : POSTHOG.API_KEYS.STAGING;

    if (isPosthogForbidden()) {
        return;
    }

    posthog.init(apiKey, {
        api_host: POSTHOG.API_HOST,
        autocapture: false,
        capture_pageleave: false,
        capture_pageview: false,
        disable_session_recording: true,
        persistence: 'localStorage+cookie',
        sanitize_properties: (properties: Properties): Properties => {
            const sanitized = { ...properties };

            try {
                const keysToSanitize = ['$current_url', '$initial_current_url', '$referrer', '$initial_referrer'];

                keysToSanitize.forEach((key: string) => {
                    if (!sanitized[key]) {
                        return;
                    }

                    const newURL = new URL(sanitized[key]);

                    sanitized[key] = `${newURL.origin}${newURL.pathname}`;
                });
            } catch (error) {
                console.error(error);
            }

            return sanitized;
        }
    });
};

export const identifyUserForPosthog = (userId: number): void => posthog?.identify(userId.toString());

export const trackPosthogEvent = (eventName: string, props = {}) => {
    if (isPosthogForbidden()) {
        return;
    }

    posthog.capture(eventName, props);
};

export const trackPosthogPurchase = (purchasedMaterials: OrderItems[]) => {
    const interactiveMaterials = purchasedMaterials.filter((material) => isInteractiveMaterial(material.material));
    const isInteractiveAvailable = interactiveMaterials.length > 0;

    if (isInteractiveAvailable) {
        interactiveMaterials.forEach((interactiveMaterial) => {
            const { finalPrice, material } = interactiveMaterial;
            const isStandalone = isInteractiveStandaloneMaterial(material);

            trackPosthogEvent('i_interactive_acquired', { price: finalPrice, isStandalone });
        });
    }
};

export const usePostVersionOverride = (): void => {
    const params = serializeQueryParams();

    const flags = posthog.featureFlags.getFlags();
    const newOverrides: Record<string, string> = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const flag of flags) {
        if (flag in params) {
            const value = params[flag] as string;

            console.log(`Overriding ${flag} with variant ${value}`);
            newOverrides[flag] = value.toString();
        }
    }
    const overridesAreSet = Object.keys(newOverrides).length > 0;

    if (overridesAreSet) {
        posthog.featureFlags.override(newOverrides);
    }
};

export const getPosthogFlagsCalled = (): Record<string, string | boolean> => {
    if (!posthog) {
        return {};
    }

    const allPosthogFlags = posthog.featureFlags.getFlagVariants();

    const keysOfFlagsCalled = Object.keys(posthog.get_property('$flag_call_reported') ?? {}) ?? [];

    return keysOfFlagsCalled.reduce((flagsNeeded, key) => {
        Boolean(allPosthogFlags[key]) && (flagsNeeded[key] = allPosthogFlags[key]);

        return flagsNeeded;
    }, {} as Record<string, string | boolean>);
};
