import session from './session';
import { world } from '../languages';

export const WEBSOCKET_OPEN_STATE_CODE = 1;

function WebsocketClient(callback) {
    const HEARTBEAT_INTERVAL = 30000;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    this.instance = null;
    this.reconnectTimeout = 2;
    this.heartbeatIntervalHandler = 0;
    this.connect = () => {
        const host = process.env.REACT_APP_API_URL?.replace('/api/', '').replace('https://', '');

        // console.info(new Date(), 'Connecting to WebSocket server.');
        _this.instance = new WebSocket(`wss://${host}:443/notifications`);
        _this.instance.onopen = async () => {
            _this.reconnectTimeout = 2;
            console.info(new Date(), 'Connected to WebSocket server.');
            _this.instance.send(
                JSON.stringify({
                    access_token: session.getAccessToken(),
                    type: 'handshake',
                    world
                })
            );

            if (_this.heartbeatIntervalHandler) {
                clearInterval(_this.heartbeatIntervalHandler);
            }
            _this.heartbeatIntervalHandler = setInterval(() => {
                console.info(new Date(), 'Heartbeat');
                if (_this.instance.readyState === WEBSOCKET_OPEN_STATE_CODE) {
                    _this.instance.send(
                        JSON.stringify({
                            type: 'heartbeat'
                        })
                    );
                }
            }, HEARTBEAT_INTERVAL);
        };

        _this.instance.onmessage = (e) => {
            // console.info('Received message:', e.data);
            callback(JSON.parse(e.data));
        };

        _this.instance.onerror = (err) => {
            // console.error(new Date(), 'Socket encountered error: ', err, 'Closing socket');
            _this.instance.close();
        };

        _this.instance.onclose = (e) => {
            if (String(e.reason).indexOf('no_reconnect') >= 0) {
                // console.info(new Date(), 'Socket is closed. No reconnect.', e.reason);

                return;
            }

            console.info(
                new Date(),
                `Socket is closed. Reconnect will be attempted in ${_this.reconnectTimeout} seconds.`,
                e.reason
            );

            setTimeout(() => {
                _this.connect();
            }, 1000 * _this.reconnectTimeout++);
        };
    };
    this.connect();
}

export default WebsocketClient;
