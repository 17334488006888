// import jwtHelper from './jwt-helper';
// import authorDomain from './domain-manager';

const session = {
    accessTokenValid(): boolean {
        try {
            const token = this.getAccessToken();

            return !(typeof token !== 'string' || token === 'undefined');
        } catch (e) {
            return false;
        }
    },

    clearAccessToken(): void {
        window.storage.removeItem('access_token');
    },

    clearError(): void {
        window.storage.removeItem('lastError');
    },

    getAccessToken(): string {
        return window.storage.getItem('access_token');
    },

    hasPreviousError(): boolean {
        return window.storage.getItem('lastError') !== '';
    },

    impersonated(): boolean {
        return Number(window.storage.getItem('impersonated')) === 1;
    },

    isLoggedIn(): boolean {
        return window.storage.getItem('isLoggedIn') === '1' || window.storage.getItem('isLoggedIn') === 'true';
    },

    setAccessToken(token: string): string {
        return window.storage.setItem('access_token', token);
    },

    setError(error: string): void {
        window.storage.setItem('lastError', error);
    },

    setLoggedIn(loggedIn: number): void {
        window.storage.setItem('isLoggedIn', loggedIn);
    }
};

export default session;
