import session from './session';
import isUITest from './is-ui-test';

export default function () {
    // const whiteListedIDs = [];
    // const id = window.storage.getItem('userId');
    // const evenUserId = Number(id) > 0 && (Number(id) % 2 === 0);
    const impersonated = Number(window.storage.getItem('impersonated')) === 1;
    const permissions = {
        showPopup: false
    };

    if (session.isLoggedIn() && !impersonated && !isUITest()) {
        permissions.showPopup = true;
    }

    return permissions;
}
