import qs, { ParsedQuery } from 'query-string';

const makeUrl = (
    url: string,
    pathParams = {},
    queryParams?: Record<string, string | number | boolean> | ParsedQuery
) => {
    Object.keys(pathParams).forEach((k) => {
        url = url.replace(`:${k}`, pathParams[k]).replace('*', '').replace('?', '');
    });

    if (queryParams) {
        url = `${url}?${qs.stringify(queryParams)}`;
    }

    return url;
};

export default makeUrl;
