import API_URLS from '../constants/api-urls';
import axios from '../utils/axios';

let baseFavorite = {
    list: [] as Material[],
    total: 0
};

type GetItemsWithPaginator = {
    favorite: Favorite;
    limit: number;
    page: number;
};

type UnfollowByLink = {
    partition: string;
    token: string;
};

const subscriptions = {
    follow: (params = {}, anonymous = false) => axios.post(anonymous ? API_URLS.SUBSCRIPTION.FOLLOW_ANONYMOUS : API_URLS.SUBSCRIPTION.FOLLOW, params),
    followSchool: (params = {}) => axios.post(API_URLS.SUBSCRIPTION.FOLLOW_SCHOOL, params),
    getFollowed: (params = {}, anonymous = false) => axios.get(anonymous ? API_URLS.SUBSCRIPTION.GET_FOLLOWED_ANONYMOUS : API_URLS.SUBSCRIPTION.GET_FOLLOWED, { params }),
    getItemsWithPaginator: ({ favorite, favorite: { list = [] }, page, limit }: GetItemsWithPaginator) => {
        const getRes = () => ({
            data: {
                items: baseFavorite.list.slice((page - 1) * limit, page * limit),
                total: baseFavorite.total
            }
        });

        if (list.length) {
            baseFavorite = favorite;
        }

        // @ts-ignore
        return new Promise((resolve) => {
            if (page * limit > baseFavorite.list.length && page * limit < baseFavorite.total) {
                subscriptions.getFollowed({ limit, page, type: 'material' }).then((r) => {
                    resolve({ data: r.data });
                });
            } else {
                resolve(getRes());
            }
        });
    },
    unfollow: (params = {}, anonymous = false) => axios.post(anonymous ? API_URLS.SUBSCRIPTION.UNFOLLOW_ANONYMOUS : API_URLS.SUBSCRIPTION.UNFOLLOW, params),
    unfollowByLink: ({ partition, token, ...rest } = {} as UnfollowByLink) =>
        axios.post(`${API_URLS.SUBSCRIPTION.GET_FOLLOWED}/${partition}/${token}`, rest),
    unfollowSchool: (params = {}) => axios.post(API_URLS.SUBSCRIPTION.UNFOLLOW_SCHOOL, params)
};

export default subscriptions;
