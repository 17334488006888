export const VERSION_SET = 'version/SET';

export const INITIALIZATION_SET = 'initialization/SET';

const initialState = {};

export default (state = initialState, action: { data: ABTest; type: string }): ABTest => {
    switch (action.type) {
        case VERSION_SET:
            return {
                ...state,
                ...action.data
            };
        case INITIALIZATION_SET:
            return {
                ...state,
                initialized: true
            };
        default:
            return state;
    }
};
