const detectDevice = (): string => {
    const agent = navigator.userAgent;
    let device;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(agent)) {
        device = 'tablet';
    } else if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            agent
        )
    ) {
        device = 'mobile';
    } else {
        device = 'desktop';
    }

    return device;
};

export default detectDevice;
