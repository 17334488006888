const getMeta = (metaName: string) => {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i];
        }
    }

    return '';
};

const prerenderMeta = {
    set(status: number): void {
        const prerenderMetaName = 'prerender-status-code';
        const existingMeta = getMeta(prerenderMetaName);
        const link = document.createElement('meta');

        if (existingMeta) {
            existingMeta.remove();
        }

        link.name = prerenderMetaName;
        link.content = `${status}`;
        document.getElementsByTagName('head')[0].appendChild(link);

        window.prerenderReady = status === 200;
    }
};

export default prerenderMeta;
