export const SEARCH_RESULTS_SET = 'search-results/SET';
export const SEARCH_RESULTS_CLEAR = 'search-results/CLEAR';

const initialState = {};

export type SearchResultsState = {
    [key: string]: {};
};

type SearchResultsAction = {
    payload: SearchResultsState | string;
    type: string;
};

export default (state = initialState, action: SearchResultsAction) => {
    switch (action.type) {
        case SEARCH_RESULTS_SET:
            return {
                ...state,
                ...(action.payload as SearchResultsState)
            };
        case SEARCH_RESULTS_CLEAR:
            state[action?.payload as string] = {};

            return {
                ...state
            };
        default:
            return state;
    }
};

export const setSearchResults =
    (details: {
        href: string;
        p: number;
        response: MaterialsSearchResolveDataType;
    }): ThunkAction<void, RootState, unknown, SearchResultsAction> =>
    (dispatch) => {
        dispatch({
            payload: {
                [details?.href]: details
            },
            type: SEARCH_RESULTS_SET
        });
    };

export const clearSearchResults =
    (href: string): ThunkAction<void, RootState, unknown, SearchResultsAction> =>
    (dispatch) => {
        dispatch({
            payload: href,
            type: SEARCH_RESULTS_CLEAR
        });
    };
