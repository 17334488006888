import axios from 'axios';
import API_URLS from '../constants/api-urls';
import { FOLDER_DIRECTORIES } from '../constants/folder';
import { world } from '../languages';

type CreateProps = {
    parent_id: null | number;
    title: string;
    type: FOLDER_DIRECTORIES;
    world: string;
};

type AddMaterialsProps = {
    folderIds: number[];
    materialIds: number[];
};

type ArchiveMaterialsProps = {
    directory: FOLDER_DIRECTORIES;
    materialIds: number[];
};

type UpdateProps = Partial<CreateProps>;

const foldersService = {
    addMultipleMaterials: ({ folderIds, materialIds }: AddMaterialsProps) => {
        const queryFilter = `{in: {id: [${materialIds.toString()}] }}`;

        return axios.post(`${API_URLS.FOLDERS.MATERIALS}/add-by-query`, {
            folder_ids: folderIds,
            queryFilter
        });
    },
    addSingleMaterial: ({
        materialId,
        folderIds,
        folder_type
    }: {
        folderIds: number[];
        folder_type: FOLDER_DIRECTORIES;
        materialId: number;
    }) => {
        return axios.post(`${API_URLS.FOLDERS.MATERIALS}/${materialId}/sync`, {
            folder_ids: folderIds,
            folder_type
        });
    },
    archive: ({ materialIds, directory }: ArchiveMaterialsProps) => {
        const queryFilter = `{in: {id: [${materialIds.toString()}] }}`;

        return axios.post(`${API_URLS.FOLDERS.ARCHIVE}`, {
            queryFilter,
            type: directory
        });
    },
    create: (data: CreateProps) => {
        return axios.post(API_URLS.FOLDERS.CREATE, data);
    },
    delete: ({ directory, id }: { directory: FOLDER_DIRECTORIES; id: number }) => {
        const queryFilter = `{ type: ${directory}, id: ${id}, world: "${world}" }`;

        return axios.post(API_URLS.FOLDERS.DELETE, {
            queryFilter
        });
    },
    get: (directory: FOLDER_DIRECTORIES) => {
        const query = `
            query {
                folders (
                    filter: { type: ${directory} }
                    pagination: { limit: 400 }
                ) {
                   id
                   title
                   parent_id
                   is_archive
                   folders_materials {
                      material_id
                      _total
                   }
                }
            }
        `;

        return axios.post(
            API_URLS.FOLDERS.GET,
            { query },
            {
                params: {
                    type: directory
                }
            }
        );
    },
    getFoldersByMaterialId: (directory: FOLDER_DIRECTORIES, materialId: number) => {
        const query = `
            query {
                folders_materials (
                    filter: { material_id: ${materialId}, folders__type: ${directory} }
                    pagination: { limit: 5000 }
                ) {
                    folders {
                        id
                        is_archive
                        title
                    }
                }
            }
        `;

        return new Promise((resolve) => {
            axios
                .post(
                    API_URLS.FOLDERS.FOLDER_MATERIALS,
                    { query },
                    {
                        params: {
                            type: directory
                        }
                    }
                )
                .then((response) => {
                    const list = response.data.folders_materials;
                    const serializedList = list.map((item: { folders: Folder[] }) => item.folders[0]);

                    resolve(serializedList);
                });
        });
    },
    unarchive: ({ materialIds, directory }: ArchiveMaterialsProps) => {
        const queryFilter = `{in: {id: [${materialIds.toString()}] }}`;

        return axios.post(`${API_URLS.FOLDERS.UNARCHIVE}`, {
            queryFilter,
            type: directory
        });
    },
    update: (fields: UpdateProps, config: { directory: FOLDER_DIRECTORIES; id: number }) => {
        const queryFilter = `{ type: ${config.directory}, id: ${config.id}, world: "${world}" }`;

        return axios.post(API_URLS.FOLDERS.UPDATE, {
            fields,
            queryFilter
        });
    }
};

export default foldersService;
