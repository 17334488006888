// REFACTOR

import UserIcon from '../images/noun-image.svg';
import NoImage from '../common/noimage/noimage';

function formQueryParams(o) {
    const keys = Object.keys(o);
    const arr = [];

    keys.forEach((key) => {
        if (o[key] === 'auto' || !o[key] || key === 'url') {
            return;
        }

        arr.push(`${key}=${o[key]}`);
    });

    if (o.url) {
        arr.push(`url=${o.url}`);
    }

    return arr.join('&');
}

function handleHeight(obj = {}) {
    if (obj.url && obj.url.indexOf('/previews') > -1) {
        obj.height = Math.min(obj.height, 500) || 500;
    }

    return obj;
}

function getFirstPreviewImage(item, config) {
    if (item.firstPreviewImage) {
        if (config && config.watermarked) {
            return item.firstPreviewImage.watermarked;
        }

        return item.firstPreviewImage.plain;
    }

    return '';
}

const imgServiceHost = process.env.REACT_APP_FRONT_URL || '';

const imagePathCreator = {
    authorImage(authorsInfo, size) {
        const url = authorsInfo.details.avatar_path || authorsInfo.details.imagePath;

        if (url) {
            return this.resizedImg({ url }, { h: size.h || 80, w: size.w || 80 });
        }

        return UserIcon;
    },

    croppedImg(item, config) {
        const obj = {
            background: '255,255,255',
            type: window.canUseWebP ? 'webp' : 'jpeg',
            url: item.url
        };

        if (config) {
            obj.width = config.w || obj.width;
            obj.height = config.h || obj.height;

            obj.quality = config.q || null;
        }

        return `${imgServiceHost}/images/crop?${formQueryParams(handleHeight(obj))}`;
    },

    optimizedImg(item, config) {
        const url = getFirstPreviewImage(item, config) || item.preview || item.imgUrl;

        if (window.location.href.indexOf('.de') < 0 && url && url.indexOf('/static/media') >= 0) {
            return url;
        }

        const obj = {
            background: '255,255,255',
            // width: 145,
            height: 240,
            quality: 80,
            type: window.canUseWebP ? 'webp' : 'jpeg',
            url: url && url.indexOf('/static/media') >= 0 ? window.location.origin + url : url
        };

        if (config) {
            obj.width = config.w || obj.width;
            obj.height = config.h || obj.height;
            obj.quality = config.q || obj.quality;
        }

        if (obj.url) {
            return `${imgServiceHost}/images/convert?${formQueryParams(handleHeight(obj))}`;
        }

        return NoImage;
    },
    resizedImg(item, config) {
        if (window.location.href.indexOf('.com') < 0 && item?.url?.indexOf('/static/media') >= 0) {
            return item.url;
        }

        const obj = {
            height: 80,

            nocrop: config.nocrop,
            // width: size.w,
            type: window.canUseWebP ? 'webp' : '',
            // quality: 80,
            url: item?.url?.indexOf('/static/media') >= 0 ? window.location.origin + item.url : item.url
        };

        if (config) {
            obj.quality = config.q || null;
            obj.width = config.w || obj.width;
            obj.height = config.h || obj.height;
        }

        return `${imgServiceHost}/images/resize?${formQueryParams(handleHeight(obj))}`;
    }
};

export default imagePathCreator;
