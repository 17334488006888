import countryByIP from '../services/country-by-ip';
import { AVAILABLE_WORLDS } from '../languages/constants';
import { setCurrency } from './currency';
import session from '../utils/session';

export const GEOTARGETING_SET = 'geotargeting/SET';

const initialState: GeotargetingState = {
    countryCode: null as unknown as string,
    loaded: false
};

export type GeotargetingState = {
    countryCode: string;
    loaded: boolean;
};

type IGeotargetingAction = {
    payload: GeotargetingState;
    type: string;
};

export default (state = initialState, action: IGeotargetingAction) => {
    switch (action.type) {
        case GEOTARGETING_SET:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export const getLocation = (): ThunkAction<void, RootState, unknown, IGeotargetingAction> => (dispatch) => {
    countryByIP
        .get()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
            if (!session.isLoggedIn()) {
                setCurrency({
                    code: response.currencyCode
                })(dispatch);
            }

            dispatch({
                payload: {
                    countryCode: response.code,
                    loaded: true
                },
                type: GEOTARGETING_SET
            });
        })
        .catch(() => {
            dispatch({
                payload: {
                    countryCode: AVAILABLE_WORLDS.DE,
                    loaded: true
                },
                type: GEOTARGETING_SET
            });
        });
};
