import hostnames, { Hostnames } from '../constants/author-domains';

export type AuthorDomain = Hostnames | boolean;

let inited = false;
let authorDomain: AuthorDomain = false;

const isAuthorDomain = (): AuthorDomain => {
    if (inited) {
        return authorDomain;
    }

    hostnames.forEach((item) => {
        if (window.location.hostname.indexOf(item.name) >= 0) {
            authorDomain = item;
        }
    });

    inited = true;

    return authorDomain;
};

export default isAuthorDomain;
