export type LanguageVariation = {
    title: string;
    value: string;
};

export enum AVAILABLE_WORLDS {
    BG = 'bg', // Bulgarian
    CN = 'cn', // Chinese
    CZ = 'cz', // Czech
    DE = 'de', // German
    DK = 'dk', // Danish
    ES = 'es', // Spanish
    FI = 'fi', // Finnish
    FR = 'fr', // French
    GR = 'gr', // Greek
    HI = 'hi', // Hindi
    HR = 'hr', // Croatian
    HU = 'hu', // Hungarian
    ID = 'id', // Indonesian
    IT = 'it', // Italian
    JP = 'jp', // Japanese
    KR = 'kr', // Korean
    KZ = 'kz', // Kazakh
    MY = 'my', // Malay
    NL = 'nl', // Dutch
    NO = 'no', // Norwegian
    PL = 'pl', // Polish
    RO = 'ro', // Romanian
    RS = 'rs', // Serbian
    SE = 'se', // Swedish
    SK = 'sk', // Slovak
    TH = 'th', // Thai
    UA = 'ua' // Ukrainian
}

export const WORLD_GROUPS = {
    MAIN: [
        AVAILABLE_WORLDS.DE,
        AVAILABLE_WORLDS.ES,
        AVAILABLE_WORLDS.FR,
        AVAILABLE_WORLDS.GR,
        AVAILABLE_WORLDS.IT
    ] as string[],
    SUPPLEMENTARY: [] as string[]
};

// @ts-ignore
WORLD_GROUPS.SUPPLEMENTARY = Object.values(AVAILABLE_WORLDS).filter((item: AVAILABLE_WORLDS) => {
    const isMainWorld = WORLD_GROUPS.MAIN.includes(item);

    return !isMainWorld;
});

export const FALLBACK_WORLD = AVAILABLE_WORLDS.DE;
export const languageVariations: { [key: string]: LanguageVariation[] } = {
    es: [
        {
            title: 'Español',
            value: 'es'
        },
        {
            title: 'Català',
            value: 'ca'
        },
        {
            title: 'Euskara',
            value: 'eu'
        },
        {
            title: 'Galego',
            value: 'gl'
        },
        {
            title: 'Sin texto',
            value: ''
        }
    ]
};
