import { Dispatch } from 'redux';
import API_URLS from '../constants/api-urls';
import { world } from '../languages';
import analytics from '../services/analytics';
import qualityEvents from '../services/quality-events';
import subscriptions from '../services/subscriptions';
import axios from '../utils/axios';
import session from '../utils/session';

export const FAVORITE_GET = 'favorite/GET';
export const SCHOOL_WISHLIST_GET = 'schoolWishlist/GET';

const initialState: Favorite = {
    list: [],
    loaded: false,
    schoolWishlist: []
} as unknown as Favorite;

type FavoriteActionType = Favorite & {
    type: string;
};

export default (state = initialState, action: FavoriteActionType) => {
    switch (action.type) {
        case FAVORITE_GET:
            return {
                ...state,
                list: action.list,
                loaded: action.loaded,
                total: action.total
            };
        case SCHOOL_WISHLIST_GET:
            return {
                ...state,
                loaded: action.loaded,
                schoolWishlist: action.list,
                totalSchool: action.total
            };
        default:
            return state;
    }
};

const getFavByURL = (url: string, dispatch: Dispatch, type = FAVORITE_GET) => {
    const schema = `{
            wishlist (
                filter: { and: [
                    { world: "${world}" }
                    { isActive: true }
                    { deletedAt: null }
                ] },
                pagination: { limit: 3000 }
            ) {
                    id
                    _total
                }
        }`;

    axios.post(url, { query: schema }).then((response) => {
        const { wishlist } = response.data;
        let total = 0;

        if (wishlist[0] && wishlist[0].id) {
            total = wishlist[0]._total;
        }

        dispatch({
            list: wishlist,
            loaded: true,
            total,
            type
        });
    });
};

export const getFavorite = () => (dispatch: Dispatch) => {
    if (session.isLoggedIn()) {
        axios
            .post(API_URLS.SUBSCRIPTION.ANONYMOUS_PERSIST)
            .then((r) => {})
            .finally(() => {
                getFavByURL(`${API_URLS.WISHLIST}/query`, dispatch);
            });
    } else {
        getFavByURL(`${API_URLS.ANONYMOUS_WISHLIST}/query`, dispatch);
    }
};

export const getSchoolWishlist = () => (dispatch: Dispatch) => {
    getFavByURL(API_URLS.SCHOOL_WISHLIST, dispatch, SCHOOL_WISHLIST_GET);
};

export const changeSchoolWishlist =
    (material: Material, add: boolean) => (dispatch: Dispatch, getState: () => RootState) => {
        const action = add ? 'followSchool' : 'unfollowSchool';

        return new Promise((resolve) => {
            subscriptions[action]({
                subjectId: material.id,
                subjectType: 'material',
                world
            }).then(() => {
                const favouriteMaterials: Favorite = getState().main.favorite;

                if (add) {
                    favouriteMaterials.schoolWishlist.unshift(material);
                    favouriteMaterials.totalSchool += 1;
                } else {
                    favouriteMaterials.schoolWishlist.forEach((item, index) => {
                        if (item.id.toString() === material.id.toString()) {
                            favouriteMaterials.schoolWishlist.splice(index, 1);
                        }
                    });
                    favouriteMaterials.totalSchool -= 1;
                }

                dispatch({
                    list: favouriteMaterials.schoolWishlist,
                    total: favouriteMaterials.totalSchool,
                    type: SCHOOL_WISHLIST_GET
                });
                resolve(null);
            });
        });
    };

export const changeFavorite = (material: Material, add: boolean) => (dispatch: Dispatch, getState: () => RootState) => {
    const action = add ? 'follow' : 'unfollow';

    subscriptions[action](
        {
            subjectId: material.id,
            subjectType: 'material',
            world
        },
        !session.isLoggedIn()
    );

    const favouriteMaterials: Favorite = getState().main.favorite;

    if (add) {
        analytics.push({
            event: 'gtmEvent',
            eventAction: 'clickAddToFavorites',
            eventCategory: 'mkt-userInteraction',
            eventLabel: '',
            eventNonInteraction: false
        });
        qualityEvents.track({
            ...qualityEvents.extractMaterialsData(material),
            type: 'addToFavorites'
        });
        favouriteMaterials.list.unshift(material);
        favouriteMaterials.total += 1;
    } else {
        analytics.push({
            event: 'gtmEvent',
            eventAction: 'clickRemoveFromFavorites',
            eventCategory: 'mkt-userInteraction',
            eventLabel: '',
            eventNonInteraction: false
        });
        qualityEvents.track({
            ...qualityEvents.extractMaterialsData(material),
            type: 'removeFromFavorites'
        });
        favouriteMaterials.list.forEach((item, index) => {
            if (item.id.toString() === material.id.toString()) {
                favouriteMaterials.list.splice(index, 1);
            }
        });
        favouriteMaterials.total -= 1;
    }

    dispatch({
        list: favouriteMaterials.list,
        total: favouriteMaterials.total,
        type: FAVORITE_GET
    });
};
