import { world } from '../../../languages';
import axios from '../../../utils/axios';

const authorService = {
    get: (user_id: number) => {
        const query = `query {user_details (filter: {user_id: ${user_id}, world: "${world}"}, order_by: {sort: id, order: ASC}) {
                id
                user_id
                public_name
                world
                is_private_profile
                slug
                total_followers
                subtitle
                image_path
                author_since
                is_seller
                total_followings
                description
                total_materials
                teachable_certified
                profile_background_path
                profile_background_data
                following
                created_at
                facebook_profile
                instagram_profile
                pinterest_profile
            } }
        `;

        return axios.post('v3/user-details/query', { query }).then((r) => {
            if (!r?.data?.user_details[0]) {
                return {};
            }
            /* eslint-disable @typescript-eslint/no-explicit-any */
            const user = r?.data?.user_details[0] as any;

            return {
                becameSellerAt: user.author_since,
                createdAt: user.created_at,
                details: {
                    description: user.description || '',
                    facebookProfile: user.facebook_profile,
                    imagePath: user.image_path,
                    instagramProfile: user.instagram_profile,
                    pinterestProfile: user.pinterest_profile,
                    privateProfile: user.is_private_profile,
                    profileBackgroundData: user.profile_background_data ? JSON.parse(user.profile_background_data) : {},
                    profileBackgroundPath: user.profile_background_path,
                    publicName: user.public_name,
                    subtitle: user.subtitle,
                    teachableCertified: user.teachable_certified,
                    totalMaterials: user.total_materials
                },
                followersNumber: user.total_followers,
                following: user.following,
                followingNumber: user.total_followings,
                id: user.user_id,

                isSeller: user.is_seller,

                slug: user.slug,
                // @ts-ignore
                total: r.data.user_details[0].total_followings
            };
        });
    }
};

export default authorService;
