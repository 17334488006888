import { world } from '../../languages';
import isSupplementaryWorld from '../../utils/is-supplementary-world';

const de = require('../../images/noimage-de.png');
const es = require('../../images/noimage-es.png');
const fr = require('../../images/noimage-fr.png');
const it = require('../../images/noimage-it.png');
const gr = require('../../images/noimage-gr.png');

// eslint-disable-next-line import/no-mutable-exports
let imgUrl = { de, es, fr, gr, it }[world];

if (isSupplementaryWorld()) {
    // eslint-disable-next-line global-require
    imgUrl = require('../../images/noimage-common.png');
}

export default imgUrl;
