import serializeQueryParams from './serialize-query-params';

const urlContainsParam = (param: string, value?: string) => {
    const queryParams = serializeQueryParams();

    if (value) {
        return String(queryParams[param]) === value;
    }

    return Boolean(queryParams[param]);
};

export default urlContainsParam;
