import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { FOLDER_DIRECTORIES } from '../constants/folder';
import foldersService from '../services/folders-service';
import buildFoldersTree from '../utils/build-folders-tree';

export const FOLDERS_GET = 'folders/GET';
export const FOLDERS_SUCCESS_ACTION = 'folders/succesAction';

export type FolderState = {
    actionSuccessMessage: string;
    list: Folder[];
    archive?: Folder;
    loaded: boolean;
    tree: Folder[];
};

export type FoldersState = {
    [FOLDER_DIRECTORIES.MY_MATERIALS]: FolderState;
    [FOLDER_DIRECTORIES.WISHLIST]: FolderState;
    [FOLDER_DIRECTORIES.MY_UPLOADS]: FolderState;
};

const initialState: FoldersState = {
    [FOLDER_DIRECTORIES.MY_MATERIALS]: {
        actionSuccessMessage: '',
        archive: {} as Folder,
        list: [],
        loaded: false,
        tree: []
    },
    [FOLDER_DIRECTORIES.WISHLIST]: {
        actionSuccessMessage: '',
        list: [],
        loaded: false,
        tree: []
    },
    [FOLDER_DIRECTORIES.MY_UPLOADS]: {
        actionSuccessMessage: '',
        list: [],
        loaded: false,
        tree: []
    }
};

type FoldersActionsType = {
    type: string;
    payload: { directory: FOLDER_DIRECTORIES } & FolderState;
};

export default (state = initialState, action: FoldersActionsType) => {
    switch (action.type) {
        case FOLDERS_GET:
            return {
                ...state,
                [action.payload.directory]: {
                    ...state[action.payload.directory],
                    archive: action.payload.archive,
                    list: action.payload.list,
                    loaded: true,
                    tree: action.payload.tree
                }
            };
        case FOLDERS_SUCCESS_ACTION:
            return {
                ...state,
                [action.payload.directory]: {
                    ...state[action.payload.directory],
                    actionSuccessMessage: action.payload.actionSuccessMessage
                }
            };
        default:
            return state;
    }
};

export const getFolders = (directory: FOLDER_DIRECTORIES) => (dispatch: Dispatch) => {
    foldersService.get(directory).then((res: AxiosResponse<{ folders: (Folder & { is_archive?: boolean })[] }>) => {
        const archive = res.data.folders.find((item) => item.is_archive);
        const list = res.data.folders.filter((item) => !item.is_archive);

        dispatch({
            payload: {
                archive,
                directory,
                list,
                tree: buildFoldersTree(list)
            },
            type: FOLDERS_GET
        });
    });
};

export const triggerActionSuccessMessage = (directory: FOLDER_DIRECTORIES, message: string) => (dispatch: Dispatch) => {
    dispatch({
        payload: {
            actionSuccessMessage: message,
            directory
        },
        type: FOLDERS_SUCCESS_ACTION
    });

    setTimeout(() => {
        dispatch({
            payload: {
                actionSuccessMessage: '',
                directory
            },
            type: FOLDERS_SUCCESS_ACTION
        });
    }, 8000);
};
