import { Reducer, combineReducers } from 'redux';
import main from './reducers';
import globalABTest from './reducers/global-ab-test';
import author from './routes/author/reducers';

type AsyncReducers = {
    [key: string]: Reducer;
};

const makeRootReducer = (asyncReducers?: AsyncReducers) => {
    return combineReducers({
        ...asyncReducers,
        globalABTest,
        author,
        main
    });
}

type StoreType = {
    asyncReducers: AsyncReducers;
    replaceReducer: (reducer: Reducer) => void;
};

type InjectReducerParams = {
    key: string;
    reducer: Reducer;
};

export const injectReducer = (store: StoreType, { key, reducer }: InjectReducerParams): void => {
    if (Object.prototype.hasOwnProperty.call(store.asyncReducers, key)) {
        return;
    }

    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
